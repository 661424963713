import classNames from "classnames";

import styles from "./hover-zoom.module.scss";

interface Props {
    hoverBorderShadow?: boolean;
    children: React.ReactNode;
}

export const HoverZoom = ({ children, hoverBorderShadow = false }: Props) => (
    <div
        className={classNames([
            {
                [styles.hoverZoom]: true,
                [styles.hoverBorderShadow]: hoverBorderShadow,
            },
        ])}
    >
        {children}
    </div>
);
