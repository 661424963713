import classNames from "classnames";
import { forwardRef } from "react";

import { OptionalCmsLink } from "../../interfaces";
import { ThreeDotLoader } from "../Loading";

import styles from "./button.module.scss";

export type ClickableProps = {
    cmsLink?: OptionalCmsLink;
    disabled?: boolean;
    unstyled?: boolean;
    loading?: boolean;
} & (
    | React.ButtonHTMLAttributes<HTMLButtonElement>
    | React.AnchorHTMLAttributes<HTMLAnchorElement>
);

const Clickable = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps
>(function Clickable(props, ref) {
    // hasNoLinkyProps returns true if it doesn't find either `href` or `cmsLink`
    if (hasNoLinkyProps(props)) {
        // cmsLink could exist with an undefined value and cause problems in the DOM
        const { cmsLink, ...restOfProps } = props;
        return <Button {...restOfProps} ref={ref as any} />;
    } else {
        // Otherwise it's a regular link
        return <Link {...props} ref={ref as any} />;
    }
});

export default Clickable;

function hasNoLinkyProps(
    props: any,
): props is React.ButtonHTMLAttributes<HTMLButtonElement> {
    return typeof props.href !== "string" && !props.cmsLink;
}

export const Button = forwardRef<
    HTMLButtonElement,
    React.ButtonHTMLAttributes<HTMLButtonElement> &
        Pick<ClickableProps, "unstyled" | "loading">
>(function Button({ className, unstyled, loading, ...props }, ref) {
    return (
        <button
            {...props}
            ref={ref}
            className={classNames(className, {
                [styles.buttonReset]: unstyled,
                [styles.loading]: loading,
            })}
        >
            {props.children}
            {loading && <ThreeDotLoader className={styles.loader} />}
        </button>
    );
});

export const Link = forwardRef<
    HTMLAnchorElement,
    React.AnchorHTMLAttributes<HTMLAnchorElement> &
        Pick<ClickableProps, "disabled" | "cmsLink" | "loading" | "unstyled">
>(function Link(
    { disabled, unstyled, loading, href, cmsLink, title, className, ...props },
    ref,
) {
    return (
        <a
            {...props}
            ref={ref}
            href={disabled ? undefined : href || cmsLink?.url}
            title={title || cmsLink?.title}
            className={classNames(className, {
                [styles.disabled]: !!disabled,
                [styles.linkReset]: unstyled,
            })}
        />
    );
});
