import { SkipNavContent, SkipNavLink } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css";
import { VisuallyHidden } from "@reach/visually-hidden";
import classNames from "classnames";
import UtilityBar from "components/UtilityBar";
import urls from "helpers/urls";
import { bodyScrollLockToggle, useWindowWidth } from "helpers/utils";
import debounce from "lodash.debounce";
import React, { SetStateAction, useContext, useEffect, useState } from "react";

import { CtaBlock, NavCategoryBlock } from "../../interfaces";
import BurgerIcon from "../../public/svg/burger.svg";
import Logo from "../../public/svg/emtek-logo.svg";
import HeartIcon from "../../public/svg/heart.svg";
import SchaubLogo from "../../public/svg/schaub-logo.svg";
import UserIcon from "../../public/svg/user.svg";
import CloseIcon from "../../public/svg/x.svg";
import Clickable from "../Clickable";
import { SearchInput } from "../Input/Input";
import { SiteTheme, themeContext } from "../siteContext";
import { MainMenu } from "./MainMenu";

import sassVariables from "../../styles/variables.module.scss";
import utilityBarStyles from "../UtilityBar/utility-bar.module.scss";
import styles from "./main-nav.module.scss";

export interface MainNavProps {
    mainMenu?: NavCategoryBlock[];
    utilityBarPromo: string | undefined;
    utilityBarMenu: CtaBlock[] | undefined;
}

export const MainNav: React.FC<MainNavProps> = ({
    mainMenu,
    utilityBarPromo,
    utilityBarMenu,
}) => {
    /** All search inputs under the breakpoint are collapsed by default */
    const searchCollapsedBreakpoint = parseInt(sassVariables.breakpointTablet);
    const fixedHeaderScrollPoint =
        parseInt(styles.desktopNavHeight) +
        parseInt(utilityBarStyles.utilityBarHeight);
    const isCondensed = useCondensed(fixedHeaderScrollPoint);
    const [searchCollapsed, setSearchCollapsed] = useCollapsed(
        searchCollapsedBreakpoint,
        isCondensed,
    );
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);
    const theme = useContext(themeContext);

    useEffect(() => bodyScrollLockToggle(mobileMenuActive), [mobileMenuActive]);

    const submitSearch = (
        searchString: string,
        isProductDisplaySearch: boolean = false,
    ) => {
        let params = new URLSearchParams({
            search: searchString,
        });

        // In order to limit search results only with Products Displays on dealers page.
        if (isProductDisplaySearch) {
            params.append("group_type", "product_display");
        }
        window.location.assign(`${urls.search}?${params.toString()}`);
    };

    return (
        <>
            <header className={styles.headerWrapper}>
                <SkipNavLink />
                <UtilityBar promo={utilityBarPromo} menu={utilityBarMenu} />
                <nav
                    aria-label="Main Navigation"
                    className={classNames({
                        [styles.mainNav]: true,
                        [styles.condensed]: isCondensed,
                    })}
                >
                    <div className={styles.content}>
                        <div className={styles.logoAndMenu}>
                            {theme.site === SiteTheme.EMTEK && <EmtekLogoNav />}
                            {theme.site === SiteTheme.SCHAUB && (
                                <SchaubLogoNav />
                            )}
                        </div>
                        <div
                            className={classNames({
                                [styles.burgerSearch]: true,
                                [styles.burgerSearchOpen]: !searchCollapsed,
                            })}
                        >
                            {searchCollapsed && (
                                <BurgerMenu
                                    mobileMenuActive={mobileMenuActive}
                                    setMobileMenuActive={setMobileMenuActive}
                                    setActiveSubMenu={setActiveSubMenu}
                                />
                            )}
                            <span className={styles.searchWrapper}>
                                {!searchCollapsed && (
                                    <CloseClickable
                                        setSearchCollapsed={setSearchCollapsed}
                                    />
                                )}
                                <SearchInput
                                    submit={submitSearch}
                                    placeholder="Search"
                                    className={styles.searchInput}
                                    collapsed={searchCollapsed}
                                    setCollapsed={setSearchCollapsed}
                                />
                            </span>
                        </div>
                        <div
                            className={classNames({
                                [styles.accountNav]: true,
                                [styles.accountNavOpen]: !searchCollapsed,
                            })}
                        >
                            <ul className={styles.accountNavList}>
                                <li className={styles.condensedSearchWrapper}>
                                    <SearchInput
                                        submit={submitSearch}
                                        placeholder="Search"
                                        className={styles.searchInput}
                                        collapsed={searchCollapsed}
                                        setCollapsed={setSearchCollapsed}
                                    />
                                </li>
                                {!searchCollapsed && (
                                    <li
                                        className={
                                            styles.condensedSearchWrapper
                                        }
                                    >
                                        <CloseClickable
                                            setSearchCollapsed={
                                                setSearchCollapsed
                                            }
                                        />
                                    </li>
                                )}
                                <li>
                                    <Clickable
                                        href={urls.favorites}
                                        className={styles.navIcon}
                                    >
                                        <HeartIcon aria-hidden />
                                        <span
                                            className={`${styles.accountLinkText} al-main-nav__fav`}
                                        >
                                            Favorites
                                        </span>
                                    </Clickable>
                                </li>
                                <li>
                                    <Clickable
                                        href={urls.accountOverview}
                                        className={styles.navIcon}
                                    >
                                        <UserIcon aria-hidden />
                                        <span
                                            className={`${styles.accountLinkText} al-main-nav__acct`}
                                        >
                                            Account
                                        </span>
                                    </Clickable>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {mainMenu && (
                        <MainMenu
                            menu={mainMenu}
                            utilityBarMenu={utilityBarMenu}
                            activeSubMenu={activeSubMenu}
                            mobileMenuActive={mobileMenuActive}
                            setActiveSubMenu={setActiveSubMenu}
                            condensedLayout={isCondensed}
                        />
                    )}
                </nav>
            </header>
            <SkipNavContent />
        </>
    );
};

const EmtekLogoNav = () => (
    <Clickable href="/" className={styles.logoBlock}>
        <VisuallyHidden>Emtek</VisuallyHidden>
        <Logo className={`${styles.logo} ${styles.svg}`} aria-hidden="true" />
    </Clickable>
);

const SchaubLogoNav = () => (
    <Clickable href="/" className={styles.logoBlock}>
        <VisuallyHidden>Schaub</VisuallyHidden>
        <SchaubLogo
            className={`${styles.logo} ${styles.svg}`}
            aria-hidden="true"
        />
    </Clickable>
);

const CloseClickable = ({
    setSearchCollapsed,
}: {
    setSearchCollapsed: (collapsed: boolean) => void;
}) => (
    <Clickable
        unstyled={true}
        className={`${styles.closeIcon} ${styles.navIcon}`}
        onClick={() => (!!setSearchCollapsed ? setSearchCollapsed(true) : null)}
    >
        <CloseIcon role="img" aria-label="" />
        <VisuallyHidden>Close search</VisuallyHidden>
    </Clickable>
);

const BurgerMenu: React.FC<{
    mobileMenuActive: boolean;
    setMobileMenuActive: (value: SetStateAction<boolean>) => void;
    setActiveSubMenu: (value: SetStateAction<string | null>) => void;
}> = ({ mobileMenuActive, setMobileMenuActive, setActiveSubMenu }) => (
    <Clickable
        unstyled={true}
        className={`${styles.navIcon} ${styles.burgerClickable}`}
        onClick={() => {
            setMobileMenuActive((state) => !state);
            setActiveSubMenu(null);
        }}
    >
        {mobileMenuActive ? (
            <>
                <CloseIcon />
                <VisuallyHidden>Close Navigation Menu</VisuallyHidden>
            </>
        ) : (
            <>
                <BurgerIcon />
                <VisuallyHidden>Open Navigation Menu</VisuallyHidden>
            </>
        )}
    </Clickable>
);

const useScrollY = () => {
    const [scrollY, setScrollY] = useState<number | undefined>(undefined);

    useEffect(() => {
        setScrollY(window.scrollY);
        const handleScrollY = debounce(() => setScrollY(window.scrollY), 200);
        window.addEventListener("scroll", handleScrollY);
        return () => window.removeEventListener("scroll", handleScrollY);
    }, []);

    return scrollY;
};

const useCondensed = (fixedHeaderScrollPoint: number) => {
    const scrollY = useScrollY();
    return typeof scrollY === "number" && scrollY > fixedHeaderScrollPoint;
};

const useCollapsed = (
    searchCollapsedBreakpoint: number,
    isCondensed: boolean,
) => {
    const [searchCollapsed, setSearchCollapsed] = useState(false);
    const windowWidth = useWindowWidth();
    const scrollY = useScrollY();

    useEffect(() => {
        setSearchCollapsed(
            isCondensed ||
                (typeof windowWidth === "number" &&
                    windowWidth < searchCollapsedBreakpoint),
        );
    }, [windowWidth, scrollY, isCondensed, searchCollapsedBreakpoint]);

    return [searchCollapsed, setSearchCollapsed] as const;
};
