import { VisuallyHidden } from "@reach/visually-hidden";
import classNames from "classnames";
import Clickable from "components/Clickable";
import { ButtonPrimary } from "components/Clickable/Buttons";
import { TextInputGroup } from "components/Input/Input";
import { RichTextContent } from "components/RichText";
import { TitledCtaList } from "components/TitledCtaList";
import { SiteTheme, themeContext } from "components/siteContext";
import { writeCMSDataClientSide } from "helpers/api";
import { basicEmailPattern, processServerSideErrors } from "helpers/forms";
import { CmsFooterNav, FooterEmailSubscribeBlock } from "interfaces";
import { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import FacebookIcon from "../../public/svg/facebook.svg";
import HouzzIcon from "../../public/svg/houzz.svg";
import InstagramIcon from "../../public/svg/instagram.svg";
import LinkedInIcon from "../../public/svg/linkedin.svg";
import PinterestIcon from "../../public/svg/pinterest.svg";
import TwitterIcon from "../../public/svg/twitter.svg";
import YouTubeIcon from "../../public/svg/youtube.svg";

import styles from "./footer-nav.module.scss";

export interface Props {
    cmsData: CmsFooterNav;
    emailSubscribe: FooterEmailSubscribeBlock | undefined;
}

export const FooterNav = ({
    cmsData: { primary_menu, social_icons, secondary_menu, legal },
    emailSubscribe,
}: Props) => {
    const theme = useContext(themeContext);
    const getIcon = (name: string) => {
        switch (name) {
            case "facebook":
                return <FacebookIcon aria-hidden="true" />;
            case "instagram":
                return <InstagramIcon aria-hidden="true" />;
            case "pinterest":
                return <PinterestIcon aria-hidden="true" />;
            case "youtube":
                return <YouTubeIcon aria-hidden="true" />;
            case "twitter":
                return <TwitterIcon aria-hidden="true" />;
            case "linkedin":
                return <LinkedInIcon aria-hidden="true" />;
            case "houzz":
                return <HouzzIcon aria-hidden="true" />;
            default:
                return <></>;
        }
    };

    return (
        <footer
            className={classNames({
                [styles.footerNav]: true,
                [styles.emtekStyle]: theme.site === SiteTheme.EMTEK,
                [styles.schaubStyle]: theme.site === SiteTheme.SCHAUB,
            })}
        >
            <FooterSubscribe body={emailSubscribe?.value.body} />
            <nav
                aria-label="General site navigation + social media + legal"
                className={styles.navLinks}
            >
                <div className={styles.primaryMenu}>
                    {primary_menu?.value.map((column) => {
                        switch (column.type) {
                            case "footer_titled_cta_list":
                                return (
                                    <div
                                        key={column.id}
                                        className={classNames({
                                            [styles.primaryDoubleWide]:
                                                column.value.width === "double",
                                            [styles.primaryHiddenOnMobile]:
                                                !column.value.visible_on_mobile,
                                        })}
                                    >
                                        <TitledCtaList
                                            title={column.value.title}
                                            cta_list={column.value.cta_list}
                                        />
                                    </div>
                                );
                            case "footer_featured_cta_list":
                                return (
                                    <ul
                                        className={styles.featuredList}
                                        key={column.id}
                                    >
                                        {column.value.cta_list.map((link) => (
                                            <li key={link.id}>
                                                <Clickable
                                                    cmsLink={link.value.link}
                                                    className={
                                                        styles.featuredLink
                                                    }
                                                >
                                                    {link.value.text}
                                                </Clickable>
                                            </li>
                                        ))}
                                    </ul>
                                );
                            default:
                                return <></>;
                        }
                    })}
                </div>
                <div className={styles.social}>
                    <ul className={styles.socialList}>
                        {social_icons?.value.map((iconItem) => (
                            <li key={iconItem.id}>
                                <Clickable cmsLink={iconItem.value.link}>
                                    <VisuallyHidden>
                                        {iconItem.value.name}
                                    </VisuallyHidden>
                                    {getIcon(iconItem.value.icon)}
                                </Clickable>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.secondaryMenu}>
                    <ul className={styles.secondaryMenuList}>
                        {secondary_menu?.value.map((item) => (
                            <li key={item.id}>
                                <Clickable cmsLink={item.value.link}>
                                    {item.value.text}
                                </Clickable>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
            {legal && (
                <RichTextContent
                    richText={legal.value}
                    className={styles.legal}
                ></RichTextContent>
            )}
        </footer>
    );
};

interface Form {
    email: string;
    server: null;
}

const FooterSubscribe = ({ body }: { body: string | undefined }) => {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<Form>();

    const [formSubmittedSucessfully, setFormSubmittedSuccessfully] = useState<
        string | false
    >(false);

    const onSubmit: SubmitHandler<Form> = async (data) => {
        const response = await writeCMSDataClientSide(
            "POST",
            "/api/email_signup",
            {},
            { email: data.email },
        );
        if (response.ok) {
            response
                .json()
                .then((responseText) =>
                    setFormSubmittedSuccessfully(responseText),
                );
        } else {
            processServerSideErrors(response, data, setError);
        }
    };

    return (
        <div className={styles.subscribe}>
            {body && <RichTextContent richText={body}></RichTextContent>}
            {!formSubmittedSucessfully ? (
                <>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                        <TextInputGroup
                            type="email"
                            placeholder="name@email.com"
                            {...register("email", {
                                required: {
                                    value: true,
                                    message: "Enter a valid email address.",
                                },
                                pattern: basicEmailPattern,
                            })}
                            errorMessage={
                                errors.server?.message || errors.email?.message
                            }
                        />
                        <ButtonPrimary
                            className="al-footer__subscribe"
                            type="submit"
                        >
                            Sign Up
                        </ButtonPrimary>
                    </form>
                </>
            ) : (
                <p aria-live="polite">{formSubmittedSucessfully}</p>
            )}
        </div>
    );
};
