import { MenuLink } from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import { DropdownMenu } from "components/Dropdown/DropdownMenu";
import { TitledCtaList } from "components/TitledCtaList";
import { truthyPartialPathMatch, useCurrentPath } from "helpers/utils";

import { NavBarProps } from "./interfaces";

import styles from "./vertical-nav-bar.module.scss";

export const VerticalNavBar = ({
    cmsData: { title, title_link, nav_items },
}: NavBarProps) => {
    const path = useCurrentPath();
    /**
     * This is how we determine if we can mark a link as active.
     * If a title link exists, it needs to be included in the search
     */
    const currentItems = (
        !title_link
            ? nav_items
            : [...nav_items].concat({
                  id: "bloop",
                  type: "cta",
                  value: {
                      text: title,
                      link: {
                          url: title_link.url || "",
                          title: title_link.title,
                      },
                  },
              })
    ).filter((item) => truthyPartialPathMatch(path, item.value.link.url));

    /**
     * If there are multiple matches, sort them. The URL with more slashes is more likely
     * to be a better match. It's a generic solution for the specific problem of a menu
     * that includes `/account` and `/account/favorites`.
     */
    const currentItem =
        currentItems.length === 1
            ? currentItems[0]
            : currentItems.sort(
                  (a, b) =>
                      b.value.link.url.split("/").length -
                      a.value.link.url.split("/").length,
              )[0];

    return (
        <nav className={styles.nav}>
            <TitledCtaList
                title={{
                    text: title,
                    link: title_link ?? undefined,
                }}
                cta_list={nav_items}
                className={styles.desktopList}
                titleClassName={styles.title}
                clickableClassName={styles.link}
            />

            {nav_items.length > 0 && (
                <DropdownMenu
                    buttonContent={
                        !!currentItem
                            ? currentItem.value.text
                            : nav_items[0].value.text
                    }
                    popoverPortal={false}
                    className={styles.mobileMenu}
                >
                    {!!title_link && (
                        <MenuLink href={title_link.url}>{title}</MenuLink>
                    )}
                    {nav_items.map((item) => (
                        <MenuLink
                            key={item.id}
                            href={item.value.link.url}
                            className={
                                item.id === currentItem?.id
                                    ? styles.menuListActive
                                    : undefined
                            }
                        >
                            {item.value.text}
                        </MenuLink>
                    ))}
                </DropdownMenu>
            )}
        </nav>
    );
};
