import classNames from "classnames";
import { SiteTheme, themeContext } from "components/siteContext";
import { truthyPathMatch, useCurrentPath } from "helpers/utils";
import { useContext } from "react";

import { CtaBlock } from "../../interfaces";
import Clickable from "../Clickable";

import emtekStyles from "./utility-bar.module.scss";
import schaubStyles from "./utility-bar.schaub.module.scss";

export type UtilityBarProps = {
    /** A single line of rich text. If it overflows, it will truncate. */
    promo: string | undefined;
    /**
     * An array of CTAs. This will keep a fixed width; if both menu and promo
     * take up too much space, menu will stay the same and promo will truncate.
     */
    menu: CtaBlock[] | undefined;
};

/**
 * This block is used to display promotional text and a small menu above the
 * main site navigation.
 */
const UtilityBar: React.FC<UtilityBarProps> = ({ promo, menu }) => {
    const path = useCurrentPath();
    const theme = useContext(themeContext);
    const styles = theme.site === SiteTheme.SCHAUB ? schaubStyles : emtekStyles;

    return !!promo || !!menu ? (
        <div className={styles.utilityBar}>
            <div
                className={classNames({
                    [styles.content]: true,
                    [styles.hasPromo]: !!promo,
                })}
            >
                {!!promo && (
                    <div
                        className={styles.promo}
                        dangerouslySetInnerHTML={{ __html: promo ? promo : "" }}
                    />
                )}
                {!!menu && (
                    <ul className={styles.menu}>
                        {menu.map((item) => (
                            <li key={item.id}>
                                <UtilityBarNavLink
                                    menuItem={item}
                                    active={truthyPathMatch(
                                        path,
                                        item.value.link.url,
                                    )}
                                    styles={styles}
                                />
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    ) : (
        <></>
    );
};

const UtilityBarNavLink: React.FC<{
    menuItem: CtaBlock;
    active: boolean;
    styles: {
        readonly [key: string]: string;
    };
}> = ({ menuItem, active, styles }) => (
    <Clickable
        className={classNames({
            [styles.menuLink]: true,
            [styles.active]: active,
        })}
        cmsLink={menuItem.value.link}
    >
        {menuItem.value.text}
    </Clickable>
);

export default UtilityBar;
