import classNames from "classnames";
import { ElementType } from "react";

import styles from "./form-label.module.scss";

export interface Props {
    children: React.ReactNode;
    required?: boolean;
    size?: "small" | "large";
    htmlFor?: string;
    id?: string;
    className?: string;
    /**
     * Render as any HTML element. If `htmlFor` is present, it'll override this and use label.
     * The best use case for this is if you are putting a label inside of a tag like <legend>
     * that isn't supposed to have a <div> inside.
     */
    as?: ElementType;
}

export const FormLabel = ({
    children,
    required = true,
    size = "small",
    htmlFor,
    id,
    className,
    as: Tag = "div",
}: Props) => {
    const classes = classNames({
        [styles.label]: size === "small",
        [styles.bigLabel]: size === "large",
        [className!]: !!className,
    });

    return !!htmlFor ? (
        <label className={classes} htmlFor={htmlFor}>
            {children}{" "}
            {required === true ? null : (
                <span className={styles.optional}>(Optional)</span>
            )}
        </label>
    ) : (
        <Tag className={classes} id={id}>
            {children}{" "}
            {required === true ? null : (
                <span className={styles.optional}>(Optional)</span>
            )}
        </Tag>
    );
};
