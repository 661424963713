import classNames from "classnames";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";
import { StreamfieldBlock } from "interfaces";
import { ElementType } from "react";

import styles from "./rich-text.module.scss";

export interface Props {
    richText: string;
    className?: string;
    as?: ElementType;
}

export const RichTextContent = ({
    richText,
    className,
    as: Tag = "div",
}: Props) => {
    return (
        <Tag
            className={`
        ${styles.richText}
        ${className ? className : ""}
      `}
            dangerouslySetInnerHTML={{ __html: richText }}
        />
    );
};

export interface AlignedRichTextCmsData {
    alignment: "left" | "center" | "right";
    background_style: string | null;
    content: string;
}

export interface AlignedRichTextProps extends AlignedRichTextCmsData {
    isSubBlock?: boolean;
}

export type AlignedRichTextBlock = StreamfieldBlock<
    "aligned_rich_text",
    AlignedRichTextCmsData
>;

export const AlignedRichText = ({
    alignment,
    background_style,
    content,
    isSubBlock,
}: AlignedRichTextProps) => {
    const width = useBlockWidth();
    if (isSubBlock) {
        return (
            <RichTextContent richText={content} className={styles[alignment]} />
        );
    }
    return (
        <section
            className={classNames({
                [styles.withBackground]: background_style,
                [`${background_style}`]: background_style,
            })}
        >
            <div
                className={classNames({
                    [styles.container]: true,
                    [styles.padded]: width === "padded",
                    [styles.full]: width === "full",
                })}
            >
                <RichTextContent
                    richText={content}
                    className={classNames({
                        [`${styles[alignment]}`]: true,
                    })}
                />
            </div>
        </section>
    );
};

export interface RichTextColumnsCmsData {
    header_content: AlignedRichTextCmsData;
    columns_background_style: string | null;
    columns: StreamfieldBlock<"rich_text", string>[];
}

export type RichTextColumnsProps = RichTextColumnsCmsData;

export type RichTextColumnsBlock = StreamfieldBlock<
    "rich_text_columns",
    RichTextColumnsCmsData
>;

export const RichTextColumns = ({
    header_content,
    columns_background_style,
    columns,
}: RichTextColumnsCmsData) => {
    const width = useBlockWidth();
    const columnsClasses = columns_background_style
        ? `${styles.columns} ${columns_background_style}`
        : styles.columns;
    return (
        <section
            className={classNames({
                [styles.richTextColumnsContainer]: true,
                [styles.padded]: width === "padded",
                [styles.full]: width === "full",
            })}
        >
            <header className={header_content.background_style || ""}>
                <AlignedRichText
                    alignment={header_content.alignment}
                    content={header_content.content}
                    background_style={null}
                    isSubBlock={true}
                />
            </header>
            <div className={columnsClasses}>
                {columns.map((column) => {
                    return (
                        <RichTextContent
                            key={column.id}
                            richText={column.value}
                        />
                    );
                })}
            </div>
        </section>
    );
};
