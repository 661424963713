import { useId } from "@reach/auto-id";
import { VisuallyHidden } from "@reach/visually-hidden";
import classNames from "classnames";
import { useRouter } from "next/router";
import React, { forwardRef, useState } from "react";

import SearchIcon from "../../public/svg/search.svg";
import Clickable from "../Clickable";
import { ErrorMessage } from "./ErrorMessage";
import { FormLabel } from "./FormLabel";

import styles from "./input.module.scss";

/** Omit `type` so we can reshape it below */
type InputAttributes = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "type"
>;

interface TextInputProps extends InputAttributes {
    /**
     * These are all visually related; inputs like `submit` are different.
     * This is required so that we make sure we're picking the right fields.
     */
    type: "email" | "number" | "password" | "tel" | "text" | "url";
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
    function TextInput(props, ref) {
        return (
            <input
                {...props}
                className={`${styles.textlikeInput} ${props.className}`}
                ref={ref}
            />
        );
    },
);

interface SearchInputProps extends InputAttributes {
    /**
     * If this remains undefined, it will act like a regular form field and the
     * search icon will become decorative. If a function is added, then the icon
     * becomes a submit button.
     */
    submit?: (searchTerm: string, checkboxChecked: boolean) => void;
    /**
     * If collapsed, just the search icon will show.
     */
    collapsed?: boolean;
    setCollapsed?: (collapsed: boolean) => void;
}

/**
 * TextInput handles most text-like inputs, but search needs more functionality.
 * SearchInput needs to be able to submit in some scenarios, and it sometimes
 * needs to collapse.
 */
export const SearchInput = ({
    submit,
    collapsed = false,
    setCollapsed,
    ...props
}: SearchInputProps) => {
    const [searchString, setSearchString] = useState("");

    const router = useRouter();
    const isProductDisplaySearch = ["product-display", "product_display"].some(
        (str) => router.asPath.includes(str),
    );

    const searchIcon = !!submit ? (
        <Clickable
            unstyled={true}
            className={styles.searchIcon}
            onClick={() => submit(searchString, isProductDisplaySearch)}
        >
            <SearchIcon role="img" aria-label="" />
            <VisuallyHidden>Submit search</VisuallyHidden>
        </Clickable>
    ) : (
        <span className={styles.searchIcon} role="img" aria-label="">
            <SearchIcon />
        </span>
    );

    const toggleIcon = (
        <Clickable
            unstyled={true}
            className={`${styles.searchIcon} ${styles.toggleIcon}`}
            onClick={() => (!!setCollapsed ? setCollapsed(false) : null)}
        >
            <SearchIcon role="img" aria-label="" />
            <VisuallyHidden>Click to enter search text</VisuallyHidden>
        </Clickable>
    );

    return (
        <>
            <div
                className={classNames({
                    [styles.inputWrapper]: true,
                    [styles.searchCollapsed]: collapsed,
                })}
            >
                <input
                    {...props}
                    type="search"
                    className={classNames(
                        styles.textlikeInput,
                        props.className,
                    )}
                    onChange={(event) => setSearchString(event.target.value)}
                    onKeyUp={(event) =>
                        event.key === "Enter" &&
                        !!submit &&
                        submit(searchString, isProductDisplaySearch)
                    }
                />
                {!collapsed && searchIcon}
                {collapsed && toggleIcon}
            </div>
        </>
    );
};

interface TextInputGroupProps extends TextInputProps {
    label?: string;
    errorMessage?: string;
    className?: string;
}

export const TextInputGroup = forwardRef<HTMLInputElement, TextInputGroupProps>(
    function TextInputGroup(
        { label, errorMessage, className, ...inputProps },
        ref,
    ) {
        const id = useId(inputProps.id);

        return (
            <div className={className}>
                {label ? (
                    <FormLabel htmlFor={id} required={inputProps.required}>
                        {label}
                    </FormLabel>
                ) : null}
                <TextInput
                    ref={ref}
                    {...inputProps}
                    id={id}
                    className={errorMessage ? styles.inputError : ""}
                />
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </div>
        );
    },
);

export const PasswordInputGroup = forwardRef<
    HTMLInputElement,
    Omit<TextInputGroupProps, "type">
>(function PasswordInputGroup({ label, errorMessage, ...props }, ref) {
    const [showPassword, setShowPassword] = useState(false);

    const id = useId(props.id);

    return (
        <>
            <div className={styles.inputWrapper}>
                <FormLabel htmlFor={id} required={props.required}>
                    {!!label ? label : "Password"}
                </FormLabel>
                <div className={styles.passwordInput}>
                    <input
                        {...props}
                        type={showPassword ? "" : "password"}
                        ref={ref}
                        className={classNames(
                            {
                                [styles.textlikeInput]: true,
                                [styles.inputError]: errorMessage,
                            },
                            props.className,
                        )}
                        onChange={props.onChange}
                    />
                    <Clickable
                        unstyled={true}
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className={styles.passwordShowHide}
                    >
                        {showPassword ? "Hide" : "Show"}
                    </Clickable>
                </div>
                {errorMessage ? (
                    <ErrorMessage>{errorMessage}</ErrorMessage>
                ) : null}
            </div>
        </>
    );
});

type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    function Textarea(props, ref) {
        return (
            <textarea
                {...props}
                className={`${styles.textarea} ${props.className}`}
                ref={ref}
            ></textarea>
        );
    },
);

interface TextAreaGroupProps extends TextAreaProps {
    label?: string;
    errorMessage?: string;
    className?: string;
}

export const TextAreaGroup = forwardRef<
    HTMLTextAreaElement,
    TextAreaGroupProps
>(function TextAreaGroup({ label, errorMessage, className, ...props }, ref) {
    const id = useId(props.id);

    return (
        <div className={className}>
            {label ? (
                <FormLabel htmlFor={id} required={props.required}>
                    {label}
                </FormLabel>
            ) : null}
            <TextArea
                ref={ref}
                {...props}
                id={id}
                className={errorMessage ? styles.inputError : ""}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
    );
});
