import { createContext } from "react";

export const enum SiteTheme {
    EMTEK = "Emtek",
    SCHAUB = "Schaub",
}

export type PartiallyConfiguredTheme = {
    site: SiteTheme;
};

export const themeContext = createContext<PartiallyConfiguredTheme>({
    site: SiteTheme.EMTEK,
});

export function ThemeProvider({
    children,
    ...value
}: PartiallyConfiguredTheme & { children?: any }) {
    return (
        <themeContext.Provider value={value}>{children}</themeContext.Provider>
    );
}
