import { CmsCtaOptional } from "interfaces";
import React from "react";

import Clickable, { ClickableProps } from ".";

/**
 * Possible overengineering. But the core idea is that an OptionalCTABlock
 * might be sending text, a link, or neither, and we want to handle it in a
 * uniform way.
 *
 * Makes it easy to get an unstyled clickable, lets you do other clickables
 * and custom children if you need.
 */
export const OptionalCta = ({
    cta,
    className,
    component: Component = Clickable,
    children,
}: {
    cta: CmsCtaOptional;
    className?: string;
    component?: React.FC<ClickableProps>;
    children?: React.ReactNode;
}) => {
    if (!cta.text) return <></>;
    return cta.link?.url ? (
        <Component className={className} cmsLink={cta.link}>
            {children ? children : cta.text}
        </Component>
    ) : (
        <span className={className}>{children ? children : cta.text}</span>
    );
};
