const urls = {
    search: "/search/",
    accountOverview: "/account/",
    accountInfo: "/account/information/",
    favorites: "/account/favorites/",
    favoriteListPage: (id: number) => `/account/favorites/${id}/`,
    register: "/account/auth/register/",
    signIn: "/account/auth/sign-in/",
    signOut: "/account/auth/sign-out/",
    changePassword: "/account/auth/change-password/",
    forgotPassword: "/account/auth/forgot-password/",
    terms: "/terms-and-conditions/",
    privacy: "/privacy-policy/",
    prop65: "/our-story/assa-abloy/product-stewardship/",
    dealerLocator: "/where-to-buy/",
    dealerSignIn: "/dealer-sign-in/",
    forDealers: "/for-dealers/",
    visualizer: "/visualizer/",
    submitForm: (pageId: number) => `/api/submit_form/${pageId}/`,
};

export default urls;
